* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif;*/
  font-family: "Courier New", monospace;
}

.home,
.recipes,
.travel,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.recipes {
  background-image: url("img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.travel {
  background-image: url("img-1.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

