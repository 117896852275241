.travel-caption p {
  font-size: 10px !important;
  border-bottom: 5px groove #000000;
  border-width: thin;
}

.above h1 {
  margin-left: 10px;
  padding-top: 20px;
}

.above h2 {
  margin: 20px 500px;
  background-size: auto;
  /*font-family: "Open Sans", sans-serif;*/
  font-family: "Courier New", monospace;
  font-size: 20px;
  border-width: thin;
  padding-bottom: 10px;
}

.day-info h3 {
  margin: 20px 500px;
  background-size: auto;
  font-family: "Courier New", monospace;
  font-size: 30px;
  border-width: thin;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.day-info p {
  margin: 20px 500px;
  background-size: auto;
  font-family: "Courier New", monospace;
  font-size: 20px;
  border-width: thin;
  padding-bottom: 10px;
}

.travel-image {
  height: auto;
  max-width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

/*IDEA: IMAGES FOR EACH DAY WITH A LINE UNDER/ABOVE TAKEN FROM THE RECIPES BORDER-TOP*/
/*IDEA2: Itineray overview at the top of the page using the recipes layout [grey background etc]*/

@media screen and (max-width: 1024px) {
  .day-info > h1 {
    margin-top: -100px;
    padding-bottom: 20px;
  }

  .day-info h2 {
    display: block;
    text-decoration: none;
    padding-bottom: 20px;
  }

  .day-info h2 {
    padding-bottom: 20px;
    width: 100%;
    margin: 0 auto;
  }

  .above h2 {
    width: 100%;
    margin: auto;
  }
  .above h2 {
    display: block;
    text-decoration: none;
  }
  .above h2 {
    width: 100%;
    margin: auto;
    padding-top: 20px;
  }

  .day-info p {
    display: block;
    text-decoration: none;
    padding-bottom: 20px;
  }

  .day-info p {
    padding-bottom: 20px;
    width: 100%;
    margin: 0 auto;
  }

  .above p {
    width: 100%;
    margin: auto;
  }
  .above p {
    display: block;
    text-decoration: none;
  }
  .above h3 {
    width: 100%;
    margin: auto;
    padding-top: 20px;
  }
}

@media screen and (max-width: 420px) {
  .day-info > h1 {
    margin-top: -100px;
    padding-bottom: 20px;
  }

  .day-info p {
    display: block;
    text-decoration: none;
    padding-bottom: 20px;
    width: 100%;
    margin: 0 auto;
  }

  .above p {
    width: 100%;
    margin: auto;
    display: block;
    text-decoration: none;
    padding-left: 5px;
    padding-right: 5px;
  }
  .above h3 {
    width: 100%;
    margin: auto;
    padding-top: 20px;
  }
}
