.recipes-css {
  margin: 20px 300px;
  background-color: #f2f2f2;
  background-size: auto;
  /*font-family: "Open Sans", sans-serif;*/
  font-family: "Courier New", monospace;
  font-size: 15px;
  border-top: 5px groove #000000;
  border-width: thin;
  padding-bottom: 10px;
}

.recipes-css ul li {
  padding: 3px 0px;
  margin-left: 30px;
}

.recipes-css ol li {
  padding: 3px 0px;
  margin-left: 40px;
}

.above h1 {
  margin-left: 10px;
  padding-top: 20px;
}

.recipes-css h2 {
  margin-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.above h3 {
  text-align: left;
  margin: 20px 300px;
}

.youtubevid {
  padding-top: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .recipes-css > h1 {
    margin-top: -100px;
    padding-bottom: 20px;
  }

  .recipes-css {
    display: block;
    text-decoration: none;
    padding-bottom: 20px;
  }

  .recipes-css {
    padding-bottom: 20px;
    width: 100%;
    margin: 0 auto;
  }

  .above {
    width: 100%;
    margin: auto;
  }
  .above {
    display: block;
    text-decoration: none;
  }
  .above h3 {
    width: 100%;
    margin: auto;
    padding-top: 20px;
  }
  .youtubevid {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .recipes-css > h1 {
    margin-top: -100px;
    padding-bottom: 20px;
  }

  .recipes-css {
    display: block;
    text-decoration: none;
    padding-bottom: 20px;
    width: 100%;
    margin: 0 auto;
  }

  .above {
    width: 100%;
    margin: auto;
    display: block;
    text-decoration: none;
    padding-left: 5px;
    padding-right: 5px;
  }
  .above h3 {
    width: 100%;
    margin: auto;
    padding-top: 20px;
  }
  .youtubevid {
    width: 100%;
    margin: 0 auto;
  }
}
